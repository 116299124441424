<template>
  <div class="content-wrapper dashboard-page">
    <h1>Dashboard Axerta</h1>
    <h2>Rilevazioni da lavorare</h2>
    <div class="d-flex align-items-start">
      <b-form-group>
        <b-form-input
          placeholder="Filtra..."
          v-model="filterText"
        ></b-form-input>
      </b-form-group>
      <b-button @click="sortByDate" class="ml-4">
        Ordina per data - {{ sortingDirection }}
      </b-button>
    </div>
    <div v-if="loading">
      <b-card>
        Caricamento in corso...
      </b-card>
    </div>
    <div v-else-if="data.length == 0">
      <b-card>
        Nessuna lavorazione da eseguire!
      </b-card>
    </div>
    <div v-else>
      <b-card
        v-for="notification in filteredData"
        :key="notification.id"
        class="notification-card mb-2"
      >
        <b-row>
          <b-col v-if="notification.url_image" cols="2"></b-col>
          <b-col :cols="notification.url_image ? 8 : 10">
            <b-row>
              <b-col class="col-12">
                <span>Dossier: </span
                ><span class="font-bold">{{ notification.NomeDossier }}</span>
              </b-col>

              <b-col class="col-12 mb-2">
                <span>Oridne di servizio: </span
                ><span class="font-bold">{{
                  notification.NomeOrdineDiServizio
                }}</span>
              </b-col>

              <b-col class="col-12 col-md-6 ">
                <span>Cie Registrato: </span
                ><span class="font-bold">{{ notification.CieRegistrato }}</span>
              </b-col>

              <b-col class="col-12 col-md-6 ">
                <span>Luogo della rilevazione: </span
                ><span class="font-bold">{{
                  notification.luogoRilevazione
                }}</span>
              </b-col>

              <b-col class="col-12 col-md-6 ">
                <span>Numero di foto caricate: </span
                ><span class="font-bold">{{ notification.numeroFoto }}</span>
              </b-col>

              <b-col class="col-12 col-md-6 ">
                <span>Data: </span
                ><span class="font-bold">{{
                  new Date(notification.dataOraRilevazione).toLocaleDateString(
                    "it",
                    {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  )
                }}</span>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="2">
            <b-button
              variant="primary"
              class="w-100"
              @click="goto(notification.idDossier)"
            >
              Apri dettaglio
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import AxiosService from "@/axiosServices/AxiosService.js"

export default {
  name: "DashboardAxerta",
  data() {
    return {
      data: [],
      loading: false,
      filterText: "",
      sortingDirection: "meno recente",
    }
  },
  methods: {
    getData() {
      this.loading = true
      this.data.splice(0)
      const service = new AxiosService("DossierManager/GetAttivitaDM")
      service
        .read()
        .then((response) => {
          this.data = response
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    sortByDate() {
      if (this.sortingDirection == "più recente") {
        this.data.sort(
          (a, b) =>
            new Date(b.dataOraRilevazione) - new Date(a.dataOraRilevazione)
        )
        this.sortingDirection = "meno recente"
      } else {
        this.data.sort(
          (a, b) =>
            new Date(a.dataOraRilevazione) - new Date(b.dataOraRilevazione)
        )
        this.sortingDirection = "più recente"
      }
    },
    goto(idDossier) {
      this.$router.push(`/axerta/lista-rilevazioni/${idDossier}`)
    },
  },
  computed: {
    filteredData() {
      const filter = this.filterText.toLowerCase().trim()
      return this.data.filter((notification) => {
        return (
          notification.NomeDossier.toLowerCase().includes(filter) ||
          notification.NomeOrdineDiServizio.toLowerCase().includes(filter) ||
          notification.CieRegistrato.toLowerCase().includes(filter) ||
          notification.luogoRilevazione.toLowerCase().includes(filter)
        )
      })
    },
  },
  created() {
    this.getData()
  },
}
</script>

<style scoped>
.dataOraRilevazione {
  border-left: 2px solid gold;
}
</style>
